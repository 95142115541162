/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HeaderTitle`
 *
 * Created: Oct 28, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { useContext } from "react";
import { PageContext } from "../../../context";

//* Main
const HeaderTitle = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { location, page } = pageData;
    const { profileData, projectData } = website;
    // - path
    const { path } = location;
    // - size
    const size = path === "profile" ? "h2" : "h1";
    // - source
    let source = page;
    if (path === "") source = projectData;
    else if (path === "profile") source = profileData;
    let { name } = source;
    // Return
    return name && (
        <span className={`${size} mb-1 align-top`}>
            {name}
        </span>
    );
};

//* Exports
export { HeaderTitle };