/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `NavBar`
 *
 * Created: Dec 25, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { getSolidDataset, getThing } from "@inrupt/solid-client";
// Project Imports
import {
    NAVBARS,
    PAGES,
    SCHEMA,
    SEMANTYK
} from "../../../../modules/Solid/Namespace/data";
import {
    getString,
    getURLAll
} from "../../../../modules/Solid/Properties/controller";
import {
    replaceWithPrimaryTopic
} from "../../../../modules/Solid/Thing/controller";

//* Main
const getNavBar = async (path = "", fetch) => {
    //  Nav Bars Document
    const dataset = await getSolidDataset(NAVBARS(""), { fetch });
    //  Nav Bar Resource
    let uri = getNavBarUri(dataset, path);
    let thing = getThing(dataset, uri);
    //Return
    return getURLAll(thing, SEMANTYK, "navBarPages");
};

const getNavBarLinks = async (dataset, locale, fetch) => {
    const path = "";
    const navBar = await getNavBar(path, fetch);
    let result = [];
    for (let link of navBar) {
        let page = getThing(dataset, link);
        page = await replaceWithPrimaryTopic(page, fetch);
        const children = getString(page, SCHEMA, "name", locale);
        const to = `/${link.replace(PAGES("").uri, "")}`;
        result.push({ children, to });
    }
    // Return
    return result;
};

const getNavBarUri = (dataset, path) => {
    let { uri } = NAVBARS(path);
    if (!(uri in dataset.graphs.default))
        uri = NAVBARS("");
    // Return
    return uri;
};

//* Exports
export { getNavBar, getNavBarLinks, getNavBarUri };