/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HeaderSubtitle`
 *
 * Created: Oct 28, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { HeaderSubtitleBadge } from "./subtitleBadge";
import { HeaderSubtitleText } from "./subtitleText";
import { useContext } from "react";
import { PageContext } from "../../../context";

//* Main
const HeaderSubtitle = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { location, page } = pageData;
    const { profileData, projectData } = website;
    // - path
    const { path } = location;
    // - source
    let source = page;
    if (path === "") source = projectData;
    else if (path === "profile") source = profileData;
    let { description, hasHeaderSubtitle, headerSubtitleIsBadge } = source;
    // Return
    const conditions = hasHeaderSubtitle && description;
    return conditions && (<>
        {headerSubtitleIsBadge ?
            <HeaderSubtitleBadge {...{ description }}/>
            :
            <HeaderSubtitleText {...{ description }}/>
        }
    </>);
};

//* Exports
export { HeaderSubtitle };