/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hooks | `Website`
 *
 * Created: Dec 27, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { useLocation as useReactLocation } from "react-router-dom";

//* Main
const useLocale = () => navigator.language.substring(0, 2);

const useLocation = () => {
    // Hooks
    const { href, pathname } = useReactLocation();
    // Hook Logic
    const path = pathname.substring(1);
    // Return
    return { href, path, pathname };
};

//* Exports
export { useLocale, useLocation };