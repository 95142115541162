/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Model | `HomeModel`
 *
 * Created: Nov 8, 2022
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Background } from "./Background";
import { Foreground } from "./Foreground";

//* Main
const HomeModel = { name: "Home", Background, Foreground };

//* Exports
export { HomeModel };