/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Page`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Form } from "react-bootstrap";
// Project Imports
import { useContext } from "react";
import { PageContext } from "../../context";

//* Main
const SearchBar = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { modelData } = pageData;
    const { colorScheme } = website;
    // - colorScheme
    const { bg, text } = colorScheme;
    // - value
    const { value, setValue } = modelData;
    // Return
    return (
        <Form.Control
            className={`bg-${bg} text-${text} border-secondary`}
            onChange={e => setValue(e.target.value)}
            value={value}
        />
    );
};

//* Exports
export { SearchBar };