/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HeaderSubtitleText`
 *
 * Created: Oct 28, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

// * Main
const HeaderSubtitleText = (props) => {
    // Props
    const { description } = props;
    // Return
    return <p className="text-muted mb-0">{description}</p>;
};

//* Exports
export { HeaderSubtitleText };