/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hooks | `Page`
 *
 * Created: Dec 27, 2022
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useSession } from "@inrupt/solid-ui-react";
import { useEffect, useState } from "react";
// Project Imports
import { getPageData, getPagesDataset } from "./controller";
import { useLocale, useLocation } from "../../modules/Website/hooks";

//* Main
const usePageData = () => {
    // Hooks
    const locale = useLocale();
    const { path } = useLocation();
    const pages = usePagesDataset();
    const { fetch } = useSession();
    const [page, setPage] = useState({});
    // Use Effect
    useEffect(() => {
        if (pages.graphs)
            getPageData(pages, fetch, locale, path).then(setPage);
    }, [fetch, locale, pages, path]);
    // Return
    return { page, setPage };
};

const usePagesDataset = () => {
    // Hooks
    const { fetch } = useSession();
    const [dataset, setDataset] = useState({});
    // Use Effect
    useEffect(() => {
        getPagesDataset(fetch).then(setDataset);
    }, [fetch]);
    // Return
    return dataset;
};

//* Exports
export { usePageData, usePagesDataset };