/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Model.Background`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import "./index.css";

//* Main
const Background = (props) => {
    // Props
    const { children } = props;
    // Return
    return children && (
        <div id="Background">
            {children}
        </div>
    );
};

//* Exports
export { Background };