/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hook | `Profile`
 *
 * Created: Dec 27, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useSession } from "@inrupt/solid-ui-react";
import { useEffect, useState } from "react";
// Project Imports
import { getProfileData } from "./controller";

//* Main
const useProfileData = () => {
    // Hooks
    const { fetch, session } = useSession();
    const [data, setData] = useState({});
    // Hook Logic
    const { webId } = session.info;
    // Use Effect
    useEffect(() => {
        if (webId)
            getProfileData(fetch, webId).then(setData);
    }, [fetch, webId]);
    // Return
    return data;
};

//* Exports
export { useProfileData };