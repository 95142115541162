/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `GraphModel.Background`
 *
 * Fun fact: The first versions of this whole file was created using ChatGPT.
 *
 * Created: Nov 3, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Hooks
// Package Imports
import { NamedNode } from "rdflib";
import { useContext, useEffect, useRef, useState } from "react";
import { ForceGraph3D } from "react-force-graph";
import { useNavigate } from "react-router-dom";
// Project Imports
import { getGraphData } from "./controller";
import { data } from "./data";
import { Model } from "../../index";
import { PageContext } from "../../../Page/context";

//* Main
const Background = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    const navigate = useNavigate();
    const fgRef = useRef();
    const [graphData, setGraphData] = useState({ nodes: [], links: [] });
    // Component Logic
    // - fetch
    const { fetch } = website.session;
    // - value
    const { value } = pageData.modelData;
    const handleLinkClick = async link => {
        const { value } = link;
        if (value)
            try {
                const url = new URL(value);
                window.open(url);
            } catch {
                navigate(value);
            }
    };
    const handleNodeClick = async node => {
        const href = node.value;
        if (href)
            try {
                const url = new URL(href);
                window.open(url);
            } catch {
                if (node instanceof NamedNode)
                    navigate(href);
            }
    };
    // Use Effect
    useEffect(() => {
        getGraphData(fetch, value).then(setGraphData);
    }, [fetch, value]);
    // Return
    return (
        <Model.Background>
            <ForceGraph3D
                graphData={graphData}
                onLinkClick={handleLinkClick}
                onNodeClick={handleNodeClick}
                ref={fgRef}
                {...data}
            />
        </Model.Background>
    );
};

export { Background };