/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Model | `GridCard`
 *
 * Created: Jan 15, 2023
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Card, Col } from "react-bootstrap";
import { useContext } from "react";
import { PageContext } from "../../../../../Page/context";

//* Main
const GridCard = (props) => {
    // Props
    const { resource, id, setValue } = props;
    // Hooks
    const { website } = useContext(PageContext);
    // Component Logic
    const { bg_color, text } = website.colorScheme;
    const handleClick = () => {
        const { uri } = resource;
        if (uri)
            try {
                const url = new URL(uri);
                setValue(url.href);
            } catch {
                setValue(resource.fragment);
            }
    };
    // Return
    return (
        <Col key={id} id={id}>
            <Card
                className={`border-secondary text-${text}`}
                onClick={handleClick}
                style={{ borderRadius: "10px", backgroundColor: bg_color }}
            >
                <Card.Body className="py-3">
                    <Card.Title className="mb-1">
                        {resource.name}
                        {!resource.name.endsWith("/") &&
                            <span className="text-muted">
                                {resource.format && `.${resource.format}`}
                            </span>
                        }
                    </Card.Title>
                    <Card.Text className="mb-0 text-muted">
                        <small>
                            {resource.resources && `${resource.resources} Resources`}
                            {resource.resources && resource.size && " • "}
                            {resource.size && resource.size}
                        </small>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
};

//* Exports
export { GridCard };