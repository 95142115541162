/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hooks | `Model`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { useEffect, useState } from "react";
import { getModel } from "./controller";
import { useLocation } from "../../modules/Website/hooks";
import { APP, POD } from "../../modules/Solid/Namespace/data";

//* Main
const useModelData = () => {
    // Hooks
    const { path } = useLocation();
    const [model, setModel] = useState(getModel(path));
    const [value, setValue] = useState("");
    // Use Effect
    useEffect(() => {
        setModel(getModel(path));
        // TODO: Replace with local storage variables
        if (path === "archive")
            setValue(POD("/public/").uri);
        if (!path || path === "solid")
            setValue(APP("/archive").uri);
    }, [path]);
    // Return
    return { model, setModel, value, setValue };
};

//* Export
export { useModelData };