/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * System | `index.js`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { createRoot } from "react-dom/client";
// Project Imports
import { App } from "./components/App";

//* Main
const element = document.getElementById("Root");
const root = createRoot(element);
root.render(<App/>);