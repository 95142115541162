/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Page`
 *
 * Created: Sept 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { getSolidDataset, getThing } from "@inrupt/solid-client";
// Project Imports
import { PAGES, SCHEMA, SEMANTYK } from "../../modules/Solid/Namespace/data";
import { getString, getURL } from "../../modules/Solid/Properties/controller";
import { replaceWithPrimaryTopic } from "../../modules/Solid/Thing/controller";

//* Main
const getPagesDataset = async (fetch) => await getSolidDataset(PAGES(""), { fetch });

const getPageData = async (dataset, fetch, locale, path) => {
    // Page Resource
    let uri = getPageURI(dataset, path);
    let page = getThing(dataset, uri);
    // Page Properties
    const author = getURL(page, SCHEMA, "author");
    const hasBackButton = getString(page, SEMANTYK, "hasBackButton");
    const hasHeaderTitle = getString(page, SEMANTYK, "hasHeaderTitle");
    const hasHeaderSubtitle = getString(page, SEMANTYK, "hasHeaderSubtitle");
    const hasModelSelector = getString(page, SEMANTYK, "hasModelSelector");
    const hasSearchBar = getString(page, SEMANTYK, "hasSearchBar");
    const headerSubtitleIsBadge = getString(page, SEMANTYK, "headerSubtitleIsBadge");
    const footerPosition = getString(page, SEMANTYK, "footerPosition");
    // Primary Topic Properties
    page = await replaceWithPrimaryTopic(page, fetch);
    const description = getString(page, SCHEMA, "description", locale);
    const name = getString(page, SCHEMA, "name", locale);
    // Return
    return {
        author,
        description,
        footerPosition,
        hasBackButton,
        hasHeaderTitle,
        hasHeaderSubtitle,
        hasModelSelector,
        hasSearchBar,
        headerSubtitleIsBadge,
        name
    };
};

const getPageURI = (dataset, path) => {
    let { uri } = PAGES(path);
    if (!(uri in dataset.graphs.default))
        uri = PAGES("notFound");
    return uri;
};

//* Exports
export { getPageData, getPagesDataset };