/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Header`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Col, Navbar, Row, Stack } from "react-bootstrap";
// Project Imports
import { ProfileImage } from "../auth/ProfileImage";
import { HeaderContainer } from "./container";
import { HeaderSubtitle } from "./content/subtitle";
import { HeaderTitle } from "./content/title";
import { NavBar } from "../NavBar";
import { BackButton } from "./content/backButton";
import { useContext } from "react";
import { PageContext } from "../../context";
import { SearchBar } from "../SearchBar";
import { ModelSelector } from "../../../Model/content/ModelSelector";

//* Main
const Header = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { location, page } = pageData;
    const { profileData, projectData } = website;
    // - path
    const { path } = location;
    // - source
    let source = page;
    if (path === "") source = projectData;
    else if (path === "profile") source = profileData;
    let { hasHeaderTitle, hasModelSelector, hasSearchBar, } = source;
    // Return
    return (
        <HeaderContainer>
            <NavBar/>
            {hasHeaderTitle && (
                <Navbar className="d-inline-flex py-0">
                    <BackButton/>
                    <ProfileImage className="mx-2" width={70}/>
                    <Stack className="mb-2">
                        <HeaderTitle/>
                        <HeaderSubtitle/>
                    </Stack>
                </Navbar>
            )}
            {(hasModelSelector || hasSearchBar) && (
                <Row className="align-items-center">
                    {hasSearchBar && (
                        <Col className="d-flex align-items-center mb-3">
                            <SearchBar/>
                        </Col>
                    )}
                    {hasModelSelector && (
                        <Col className="mb-3" md="auto">
                            <ModelSelector/>
                        </Col>
                    )}
                </Row>
            )}
        </HeaderContainer>
    );
};

//* Exports
export { Header };