/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Container | `Router`
 *
 * Created: Jan 28, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { BrowserRouter, Routes } from "react-router-dom";
import { PageContainer } from "../Page/container";

//* Main
const RouterContainer = (props) => {
    // Props
    const { children } = props;
    // Return
    return (
        <div id="Router">
            <BrowserRouter>
                <PageContainer>
                    <Routes>
                        {children}
                    </Routes>
                </PageContainer>
            </BrowserRouter>
        </div>
    );
};

//* Exports
export { RouterContainer };