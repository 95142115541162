/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Context | `Page`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { createContext } from "react";

//* Main
const PageContext = createContext({});

//* Exports
export { PageContext };