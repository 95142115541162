/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Model | `Model.Selector`
 *
 * Created: Jan 28, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useContext } from "react";
import { Nav } from "react-bootstrap";
// Project Imports
import { GridModel } from "../../models/GridModel";
import { GraphModel } from "../../models/GraphModel";
import { PageContext } from "../../../Page/context";

//* Main
const ModelSelector = () => {
    // Hooks
    const { pageData } = useContext(PageContext);
    // Component Logic
    const { modelData } = pageData;
    // - model
    const { model, setModel } = modelData;
    // Return
    return (
        <Nav defaultActiveKey={model.name} fill variant="pills">
            <Nav.Item>
                <Nav.Link
                    eventKey={GridModel.name}
                    onClick={() => setModel(GridModel)}
                >
                    🗂️ {GridModel.name}
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    eventKey={GraphModel.name}
                    onClick={() => setModel(GraphModel)}
                >
                    🫧 {GraphModel.name}
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

//* Exports
export { ModelSelector };