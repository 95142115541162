/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Math`
 *
 * Created: Dec 28, 2022
 * Modified: Dec 31, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Main
const PHI = 1.618;

//* Exports
export { PHI };