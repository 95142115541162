/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HomeModel.Foreground`
 *
 * Created: Nov 3, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import "./index.css";
import { Model } from "../../index";

//* Main
const Foreground = () => (
    <Model.Foreground>
        <div id="Stack">
            <h1 id="Title" className={"display-1"}>hi, you.</h1>
            <h2 id="Subtitle" className={"display-4"}>
                when did <span id="Slogan">ideas wonder</span> you last?
            </h2>
        </div>
    </Model.Foreground>
);

//* Exports
export { Foreground };