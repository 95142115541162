/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HomeModel.Background`
 *
 * Created: Jan 24, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Main
import { GraphModel } from "../GraphModel";

const Background = GraphModel.Background;

//* Exports
export { Background };