(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@inrupt/solid-client"), require("@inrupt/solid-client-authn-browser"), require("swr"), require("react-table"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@inrupt/solid-client", "@inrupt/solid-client-authn-browser", "swr", "react-table"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("@inrupt/solid-client"), require("@inrupt/solid-client-authn-browser"), require("swr"), require("react-table")) : factory(root["react"], root["@inrupt/solid-client"], root["@inrupt/solid-client-authn-browser"], root["swr"], root["react-table"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__577__, __WEBPACK_EXTERNAL_MODULE__606__, __WEBPACK_EXTERNAL_MODULE__928__, __WEBPACK_EXTERNAL_MODULE__767__) => {
return 