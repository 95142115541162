/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `App`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
// Project Imports
import { Router } from "../Router";
import { AppContainer } from "./container";

//* Main
const App = () => (
    <AppContainer>
        <Router/>
    </AppContainer>
);

//* Exports
export { App };