/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `ContactModel.Foreground`
 *
 * Created: Nov 3, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useContext, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
// Project Imports
import { PageContext } from "../../../Page/context";
import { Model } from "../../index";

//* Main
const Foreground = () => {
    // Hooks
    const { website } = useContext(PageContext);
    const [validated, setValidated] = useState(false);
    // Component Logic
    const { text } = website.colorScheme;
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };
    // Return
    return (
        <Model.Foreground>
            <Card className={`border-secondary text-${text}`}
                  style={{
                      backgroundColor: "#00000000",
                      borderRadius: "10px"
                  }}>
                <Card.Body>
                    <Form noValidate validated={validated}
                          onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6"
                                        controlId="validationCustom01">
                                <Form.Label className={`text-secondary`}>
                                    First Name
                                    <span
                                        className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="E.g. John"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6"
                                        controlId="validationCustom02">
                                <Form.Label
                                    className={`text-secondary`}>Last
                                    Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="E.g. Smith"
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3"
                                        controlId="validationCustom04">
                                <Form.Label
                                    className={`text-secondary`}>Mail<span
                                    className="text-danger">*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text
                                        id="inputGroupPrepend">@</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="E.g. mail@example.com"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid mail.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3"
                                        controlId="validationCustom05">
                                <Form.Label
                                    className={`text-secondary`}>Phone</Form.Label>
                                <Form.Control type="phone"
                                              placeholder="E.g. +1-650-555-1212"/>
                            </Form.Group>
                            <Form.Group as={Col} md="6"
                                        controlId="validationCustom03">
                                <Form.Label
                                    className={`text-secondary`}>WebID</Form.Label>
                                <Form.Control type="url"
                                              placeholder="E.g. https://id.semantyk.com"/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12"
                                        controlId="validationCustom05">
                                <Form.Label
                                    className={`text-secondary`}>Subject<span
                                    className="text-danger">*</span></Form.Label>
                                <Nav
                                    fill
                                    variant="pills"
                                    defaultActiveKey="question">
                                    <Nav.Item>
                                        <Nav.Link eventKey="question">🕵🏽
                                            Question</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="feedback">🌟
                                            Feedback</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="request">✍🏽
                                            Request</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="report">❗️️
                                            Report</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="join">🧑🏽‍🚀
                                            Join our Team</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="other">🔮️ Other</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12"
                                        controlId="validationCustom05">
                                <Form.Label
                                    className={`text-secondary`}>Message<span
                                    className="text-danger">*</span></Form.Label>
                                <Form.Control as="textarea"
                                              placeholder="E.g. Hello, my name is John Smith and..."
                                              required/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid message.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Group className="position-relative mb-3">
                            <Form.Label className="text-secondary">Attach
                                Files</Form.Label>
                            <Form.Control type="file" required name="file"/>
                        </Form.Group>
                        <Form.Group className={`mb-3 text-secondary`}>
                            <Form.Check
                                required
                                label={<p className="mb-0">Agree to Terms and
                                    Conditions<span
                                        className="text-danger">*</span></p>}
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                            />
                            <Form.Check
                                label="Keep me updated with the latest news 🤩"/>
                        </Form.Group>
                        <Button type="submit">Send</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Model.Foreground>
    );
};

//* Exports
export { Foreground };