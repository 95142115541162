/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Data | `GraphModel`
 *
 * Created: Jan 19, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */
//* Main
import { PHI } from "../../../../modules/Math/controller";

const data = {
    backgroundColor: "#00000000",
    cooldownTicks: PHI * 100,
    linkAutoColorBy: (link => link.value),
    linkCurvature: (link => link.curvature),
    linkCurveRotation: (link => link.curveRotation),
    linkDirectionalParticles: 1,
    linkDirectionalParticleResolution: PHI * 10,
    linkDirectionalParticleSpeed: PHI / 1000,
    linkDirectionalParticleWidth: PHI,
    linkLabel: (link => link.label || link.value),
    linkOpacity: 0.95,
    linkResolution: 16,
    linkWidth: PHI / 3,
    numDimensions: 3,
    nodeAutoColorBy: (node => node.type),
    nodeLabel: (node => `${node.label || node.value} (${node.size})`),
    nodeOpacity: 1,
    nodeRelSize: 6,
    nodeResolution: PHI * 40,
    nodeVal: (node => node.size / PHI * 4),
    showNavInfo: false
};

//* Exports
export { data };