/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Archive`
 *
 * Created: Oct 20, 2022
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { getSolidDataset, getThing } from "@inrupt/solid-client";
import { LDP } from "../Solid/Namespace/data";

const getArchiveData = async (fetch, uri) => {
    // Profile Document
    try {
        const dataset = await getSolidDataset(uri, { fetch });
        const { predicates } = getThing(dataset, uri);
        const { namedNodes } = predicates[LDP("contains").uri];
        const resources = Object.values(namedNodes).map(node => {
            return {
                isContainer: node.endsWith("/"),
                name: node.replace(uri, ""),
                uri: node
            };
        });
        return resources;
    } catch (e) {
        return [];
    }
};

export { getArchiveData };