/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Profile`
 *
 * Created: Oct 20, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { getProfileAll, getThing } from "@inrupt/solid-client";
// Project Imports
import { FOAF } from "../../Solid/Namespace/data";
import { getString } from "../../Solid/Properties/controller";

//* Main
const getProfileData = async (fetch, webId) => {
    // Profile Documents
    const profiles = await getProfileAll(webId, { fetch });
    // Selected Profile Document
    // TODO: Replace 0 with user choice
    const dataset = profiles.altProfileAll[0];
    // Profile Resource
    const thing = getThing(dataset, webId);
    // Profile Properties
    const description = webId;
    const hasBackButton = true;
    const hasHeaderTitle = true;
    const hasHeaderSubtitle = true;
    const headerSubtitleIsBadge = true;
    const img = getString(thing, FOAF, "img");
    const name = getString(thing, FOAF, "name");
    // Return
    return {
        description,
        hasBackButton,
        hasHeaderTitle,
        hasHeaderSubtitle,
        headerSubtitleIsBadge,
        img,
        name
    };
};

//* Exports
export { getProfileData };