/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Data | `Namespace`
 *
 * Created: Oct 11, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2022. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Namespace } from "rdflib";

//* Main
// External
const FOAF = Namespace("http://xmlns.com/foaf/0.1/");
const LDP = Namespace("http://www.w3.org/ns/ldp#");
const RDF = Namespace("http://www.w3.org/1999/02/22-rdf-syntax-ns#");
const RDFS = Namespace("https://www.w3.org/2000/01/rdf-schema#");
const SCHEMA = Namespace("http://schema.org/");
const VCARD = Namespace("http://www.w3.org/2006/vcard/ns#");
// Project
const POD = Namespace("https://storage.inrupt.com/93eee8ab-4002-4bbf-a457-38eab9979cc8");
const PROJECT = Namespace(POD("/public/semantyk").uri);
const APP = Namespace(PROJECT("/app").uri);
const BRAND = Namespace(PROJECT("/brand").uri);
const NAVBARS = Namespace(APP("/navBars#").uri);
const PAGES = Namespace(APP("/pages#").uri);
const SEMANTYK = Namespace("https://www.semantyk.com/archive/");

//* Exports
// External
export { FOAF, LDP, RDF, RDFS, SCHEMA, VCARD };
// Project
export { APP, BRAND, NAVBARS, PAGES, POD, PROJECT, SEMANTYK };