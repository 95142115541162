/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Project`
 *
 * Created: Dec 27, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { getSolidDataset, getThing } from "@inrupt/solid-client";
// Project
import {
    getNumber,
    getString,
    getStringAll
} from "../Solid/Properties/controller";
import { BRAND, SCHEMA, SEMANTYK } from "../Solid/Namespace/data";

//* Main
const getProjectData = async (fetch, locale) => {
    const { uri } = BRAND("");
    // Profile Document
    const dataset = await getSolidDataset(uri, { fetch });
    // Profile Thing
    const thing = getThing(dataset, uri);
    // Return
    return {
        copyrightYear: getNumber(thing, SCHEMA, "copyrightYear"),
        description: getString(thing, SCHEMA, "description", locale),
        keywords: getStringAll(thing, SCHEMA, "keywords"),
        name: getString(thing, SCHEMA, "name"),
        slogan: getString(thing, SCHEMA, "slogan", locale),
        topLevelDomain: getString(thing, SEMANTYK, "topLevelDomain")
    };
};

//* Exports
export { getProjectData };