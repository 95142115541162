/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Helmet`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Helmet as ReactHelmet } from "react-helmet-async";
// Project Imports
import { PageContext } from "../../context";
import { useContext } from "react";

//* Main
const Helmet = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { page, location } = pageData;
    const { colorScheme, locale, profileData, projectData } = website;
    // - author
    const { author } = page;
    // body
    const { body } = colorScheme;
    // - href
    const { href } = location;
    // - keywords, slogan, topLevelDomain
    const { keywords, slogan, topLevelDomain } = projectData;
    // - language
    document.documentElement.lang = locale;
    // - path
    const { path } = location;
    // - source
    let source = page;
    if (path === "") source = projectData;
    else if (path === "profile") source = profileData;
    // - title
    let { name } = source;
    if (path && name && projectData.name) name += ` | ${projectData.name}`;
    // - description
    let { description } = source;
    if (description && slogan) description = `${slogan} | ${description}.`;
    // Return
    return (
        <ReactHelmet prioritizeSeoTags>
            {/* Title */}
            <title>{name}</title>
            <meta content={name} name="title"/>
            <meta content={name} property="og:title"/>
            <meta content={name} property="twitter:title"/>
            {/* Description*/}
            <meta content={description} name="description"/>
            <meta content={description} property="og:description"/>
            <meta content={description} property="twitter:description"/>
            {/* Author */}
            <meta content={author} name="author"/>
            {/* Domain */}
            <meta content={topLevelDomain} property="twitter:domain"/>
            {/* Keywords*/}
            <meta content={keywords} name="keywords"/>
            {/* URL */}
            <link href={href} rel="canonical"/>
            <meta content={href} property="og:url"/>
            <meta content={href} property="twitter:url"/>
            {/* Body */}
            <body className={`bg-${body}`}/>
        </ReactHelmet>
    );
};

//* Exports
export { Helmet };