/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Data | `auth`
 *
 * Created: Oct 11, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { APP } from "../../../../modules/Solid/Namespace/data";

//* Main
const clientId = APP("/clientId.json").uri;
let podProviders = {
    "Inrupt Pod Spaces": "https://login.inrupt.com",
    "inrupt.net": "https://inrupt.net/",
    "Solid Community": "https://solidcommunity.net/",
    "Daniel Bakas Pod": "https://pod.danielbakas.com/"
};

//* Exports
export { clientId, podProviders };