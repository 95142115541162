/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Router.jsx`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Route } from "react-router-dom";
// Project Imports
import { Page } from "../Page";
import { RouterContainer } from "./container";

//* Main
const Router = () => (
    <RouterContainer>
        <Route path="*" element={<Page/>}/>
    </RouterContainer>
);

//* Exports
export { Router };