/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Container | `Page`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import "./index.css";
import { PageContext } from "./context";
import { useData } from "../../hooks";

//* Main
const PageContainer = (props) => {
    // Props
    const { children } = props;
    const data = useData();
    // Return
    return (
        <div id="Page">
            <PageContext.Provider value={data}>
                {children}
            </PageContext.Provider>
        </div>
    );
};

//* Exports
export { PageContainer };