/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `ProfileImage`
 *
 * Created: Oct 20, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Image } from "react-bootstrap";
import { useContext } from "react";
import { PageContext } from "../../context";

//* Main
const ProfileImage = (props) => {
    // Props
    const { className, ...rest } = props;
    // Hooks
    const { website } = useContext(PageContext);
    // Component Logic
    const { profileData } = website;
    // - img
    let { img } = profileData;
    // Return
    return img && (
        <Image
            alt="Profile"
            className={`border border-2 d-inline-block align-center ${className}`}
            roundedCircle
            src={img}
            {...rest}
        />
    );
};

//* Exports
export { ProfileImage };