/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Container | `Header`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useContext } from "react";
// Project Imports
import "./index.css";
import { PageContext } from "../../context";

//* Main
const HeaderContainer = (props) => {
    // Props
    const { children } = props;
    // Hooks
    const { website } = useContext(PageContext);
    // Component Logic
    const { text } = website.colorScheme;
    // Return
    return (
        <header id="Header" className={`text-${text} sticky-top`}>
            {children}
        </header>
    );
};

//* Exports
export { HeaderContainer };