/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `GridModel.Foreground`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Row } from "react-bootstrap";
// Project Imports
import { GridCard } from "./content/GridCard";
import { useContext, useEffect, useState } from "react";
import { getArchiveData } from "../../../../modules/Archive/controller";
import { POD } from "../../../../modules/Solid/Namespace/data";
import { Model } from "../../index";
import { PageContext } from "../../../Page/context";

//* Main
const Foreground = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    const [resources, setResources] = useState([]);
    // Component Logic
    const { value, setValue } = pageData.modelData;
    const { fetch } = website.session;
    // Use Effect
    useEffect(() => {
        if (value)
            try {
                new URL(value || POD("/public/").uri);
                getArchiveData(fetch, value).then(setResources);
            } catch (e) {
                // console.log(`Invalid URI: ${search}`);
            }
    }, [fetch, value]);
    // Return
    return (
        <Model.Foreground>
            <Row xs={1} sm={2} md={3} lg={4} xl={5} xxl={6} className="g-4">
                {resources && resources.map((resource, id) => (
                    <GridCard key={id} {...{ resource, id, setValue }}/>
                ))}
            </Row>
        </Model.Foreground>
    );

};

//* Exports
export { Foreground };