/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Footer`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Link } from "react-router-dom";
import { useContext } from "react";
// Project Imports
import { FooterContainer } from "./container";
import { LogOutButton } from "../auth/LogOutButton";
import { PageContext } from "../../context";
import { Col } from "react-bootstrap";

//* Main
const Footer = () => {
    // Hooks
    const { website } = useContext(PageContext);
    // Component Logic
    const { projectData } = website;
    //- project
    const { copyrightYear, name } = projectData;
    // Return
    const link = <Link className="text-muted" to="/">{name}</Link>;
    return (
        <FooterContainer>
            <Col sm={1} className="mx-auto">
                <LogOutButton className="mb-2"/>
            </Col>
            <small className="text-muted">
                Copyright © {link} {copyrightYear}
            </small>
        </FooterContainer>
    );
};

//* Exports
export { Footer };