/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `WebIDBadge`
 *
 * Created: Oct 20, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
// Project Imports
import { ProfileImage } from "./ProfileImage";
import { useProfileData } from "../../../../modules/User/Profile/hook";

//* Main
const WebIDBadge = () => {
    // Hooks
    const { img, name } = useProfileData();
    // Return
    return (
        <Link to={"/profile"}>
            {img ?
                <ProfileImage {...{ img, width: "40" }}/>
                :
                <Badge children={name || "Profile"}/>
            }
        </Link>
    );
};

//* Exports
export { WebIDBadge };