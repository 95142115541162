/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `LogInModal`
 *
 * Created: Oct 20, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
// Project Imports
import { handleLogin } from "./controller";
import { podProviders } from "./data";
import { PageContext } from "../../context";

//* Main
function LogInModal() {
    // Hooks
    const { website } = useContext(PageContext);
    const [show, setShow] = useState(false);
    const [oidcIssuer, setOidcIssuer] = useState(podProviders["Daniel Bakas Pod"]);
    // Component Logic
    const { session } = website;
    const { login } = session;
    // Model Logic
    const options = Object.entries(podProviders).map(([key, value], i) => (
        <option children={key} key={i} value={value}/>
    ));
    //Return
    return (<>
        {!show && (
            <Button
                children={"Log In"}
                variant="outline-primary"
                onClick={() => setShow(true)}
            />
        )}
        <Modal onHide={() => setShow(false)} show={show}>
            <Modal.Header closeButton>
                <Modal.Title children={"Authentication"}/>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label children={"Pod Provider"}/>
                        <Form.Select
                            defaultValue={oidcIssuer}
                            children={options}
                            onChange={e => setOidcIssuer(e.target.value)}
                            aria-label="Select Pod Provider"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    children={"Log In"}
                    onClick={async () => await handleLogin(login, oidcIssuer)}
                    variant="primary"
                />
            </Modal.Footer>
        </Modal>
    </>);
}

//* Exports
export { LogInModal };