/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hook | `Project`
 *
 * Created: Dec 27, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useSession } from "@inrupt/solid-ui-react";
import { useEffect, useState } from "react";
// Project Imports
import { getProjectData } from "./controller";
import { useLocale } from "../Website/hooks";

//* Main
const useProjectData = () => {
    // Hooks
    const locale = useLocale();
    const { fetch } = useSession();
    const [data, setData] = useState({});
    // Use Effect
    useEffect(() => {
        getProjectData(fetch, locale).then(setData);
    }, [fetch, locale]);
    // Return
    return data;
};

//* Exports
export { useProjectData };