/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Model | `Model`
 *
 * Created: Nov 8, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Background } from "./Background";
import { Foreground } from "./Foreground";

//* Main
const Model = { name: "Base", Background, Foreground };

//* Exports
export { Model };