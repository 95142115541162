/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Properties`
 *
 * Created: Oct 28, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import {
    getInteger,
    getStringNoLocale,
    getStringNoLocaleAll,
    getStringWithLocale,
    getStringWithLocaleAll,
    getUrl,
    getUrlAll
} from "@inrupt/solid-client";

//* Main
const getNumber = (thing, namespace, fragment) => {
    const { uri } = namespace(fragment);
    return getInteger(thing, uri);
};

const getString = (thing, namespace, fragment, locale) => {
    const { uri } = namespace(fragment);
    if (locale) {
        const result = getStringWithLocale(thing, uri, locale);
        if (result) return result;
    }
    return getStringNoLocale(thing, uri);
};

const getStringAll = (thing, namespace, fragment, locale) => {
    const { uri } = namespace(fragment);
    if (locale)
        return getStringWithLocaleAll(thing, uri, locale);
    else
        return getStringNoLocaleAll(thing, uri);
};

const getURL = (thing, namespace, fragment) => {
    const { uri } = namespace(fragment);
    return getUrl(thing, uri);
};

const getURLAll = (thing, namespace, fragment) => {
    const { uri } = namespace(fragment);
    return getUrlAll(thing, uri);
};

//* Export
export { getNumber, getString, getStringAll, getURL, getURLAll };