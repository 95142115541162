/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hooks | `App`
 *
 * Created: Dec 27, 2022
 * Modified: Jan 25, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { useLocale, useLocation } from "./modules/Website/hooks";
import { usePageData } from "./components/Page/hooks";
import { useProjectData } from "./modules/Project/hook";
import { useProfileData } from "./modules/User/Profile/hook";
import { useColorScheme } from "./modules/Website/ColorScheme/hooks";
import { useSession } from "@inrupt/solid-ui-react";
import { useModelData } from "./components/Model/hooks";

//* Main
const useData = () => ({
    pageData: {
        location: useLocation(),
        modelData: useModelData(),
        ...usePageData()
    },
    website: {
        colorScheme: useColorScheme(),
        locale: useLocale(),
        profileData: useProfileData(),
        projectData: useProjectData(),
        session: useSession()
    }
});


//* Exports
export { useData };