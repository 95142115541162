/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `NavBar`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useSession } from "@inrupt/solid-ui-react";
import { Image, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// Project Imports
import { LogInModal } from "../auth/LoginModal";
import { WebIDBadge } from "../auth/WebIDBadge";
import { useNavBarLinks } from "./hooks";
import { useContext } from "react";
import { PageContext } from "../../context";

//* Main
const NavBar = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    const navBarLinks = useNavBarLinks();
    const { session } = useSession();
    // Component Logic
    // - colorScheme
    const { bg } = website.colorScheme;
    // - navLinks
    let links = navBarLinks || [];
    let navLinks = links.map((page, key) => {
        let { to, children } = page;
        const props = { as: NavLink, children, key, to };
        return <Nav.Link {...props}/>;
    });
    // - path
    const { path } = pageData.location;
    // Return
    return (
        <Navbar expand="sm" variant={bg}>
            <Navbar.Brand as={Link} to="/">
                <Image
                    alt={`Navbar Logo`}
                    src={"/favicon.ico"}
                    width="40"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse className="justify-content-between">
                <Nav className="mr-auto" children={navLinks}/>
                {!session.info.isLoggedIn ?
                    <LogInModal/> :
                    <Nav className="mr-auto me-2">
                        {path !== "profile" && <WebIDBadge/>}
                    </Nav>
                }
            </Navbar.Collapse>
        </Navbar>
    );
};

//* Exports
export { NavBar };