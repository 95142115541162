/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Thing`
 *
 * Created: Oct 28, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

// * Imports
//  Package Imports
import { getSolidDataset, getThing } from "@inrupt/solid-client";
//  Project Imports
import { FOAF } from "../Namespace/data";
import { getURL } from "../Properties/controller";

//* Main
const replaceWithPrimaryTopic = async (thing, fetch) => {
    const primaryTopic = getURL(thing, FOAF, "primaryTopic");
    if (primaryTopic) {
        const primaryTopicDataset = await getSolidDataset(primaryTopic, { fetch });
        thing = getThing(primaryTopicDataset, primaryTopic);
    }
    // Return
    return thing;
};

//* Exports
export { replaceWithPrimaryTopic };