/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Container | `App`
 *
 * Created: Jan 28, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { SessionProvider, useSession } from "@inrupt/solid-ui-react";
import { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";

//* Main
const AppContainer = (props) => {
    // Props
    const { children } = props;
    // Hooks
    const { session } = useSession();
    // Component Logic
    const { sessionId } = session.info;
    // Return
    return (
        <div id="App">
            <StrictMode>
                <SessionProvider restorePreviousSession sessionId={sessionId}>
                    <HelmetProvider>
                        {children}
                    </HelmetProvider>
                </SessionProvider>
            </StrictMode>
        </div>
    );
};

//* Exports
export { AppContainer };