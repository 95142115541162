/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `ColorScheme`
 *
 * Created: Nov 8, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { colorSchemes } from "./data";

//* Main
const getInicialColorScheme = () => {
    const { dark, light } = colorSchemes;
    const query = "(prefers-color-scheme: dark)";
    return (window.matchMedia(query).matches ? dark : light);
};

//* Export
export { getInicialColorScheme };