/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `GraphModel`
 *
 * Fun fact: The first versions of this whole file was created using ChatGPT.
 *
 * Created: Dec 25, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import * as rdf from "rdflib";
import { Literal, NamedNode } from "rdflib";
// Project Imports
import {
    FOAF,
    RDF,
    RDFS,
    VCARD
} from "../../../../modules/Solid/Namespace/data";

//* Main
const getGraphData = async (fetch, base) => {
    const nodes = [];
    const links = [];
    // let base = APP("/archive#");
    let store = rdf.graph();
    const res = await fetch(base);
    const text = await res.text();
    try {
        rdf.parse(text, store, base);
    } catch {
    }
    for (const { subject, predicate, object } of store.statements) {
        // Subject
        let source = getNode(store, nodes, subject);
        source.size += 1;
        // Object
        let target = getNode(store, nodes, object);
        target.size += 1;
        // Predicate
        let data = getNode(store, nodes, predicate, false);
        // Link
        links.push({ source, target, ...data });
    }
    // Curved Links
    for (const { subject, object } of store.statements) {
        let curvedLinks = links.filter(link => link.source.value === subject.value && link.target.value === object.value);
        if (curvedLinks.length > 1)
            for (const i in curvedLinks) {
                const link = curvedLinks[i];
                link.curvature = 0.05;
                link.curveRotation = Math.PI * (i + 1) / curvedLinks.length;
            }
    }
    return { nodes, links };
};

const getLabel = (store, id) => {
    return (
        store.any(id, VCARD("fn")) ||
        store.any(id, FOAF("name")) ||
        store.any(id, RDFS("label"))
    );
};

const getNode = (store, nodes, resource, push = true) => {
    let resourceNode = nodes.find(node => node.value === resource.value);
    if (!resourceNode) {
        resourceNode = {
            label: getLabel(store, resource),
            size: 0,
            type: getType(store, resource),
            ...resource
        };
        if (push)
            nodes.push(resourceNode);
    }
    return resourceNode;
};

const getType = (store, node) => {
    if (node instanceof NamedNode)
        return store.each(node, RDF("type"));
    else if (node instanceof Literal)
        return node.datatype.value;
};

//* Exports
export { getGraphData };