/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `LogOutButton`
 *
 * Created: Oct 20, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PageContext } from "../../context";

//* Main
const LogOutButton = (props) => {
    // Hooks
    const navigate = useNavigate();
    const { pageData, website } = useContext(PageContext);
    // Component Logic
    const { session } = website;
    const { logout } = session;
    const { location } = pageData;
    //- path
    const { path } = location;
    const callback = async () => {
        await logout();
        navigate("/");
    };
    // Return
    return path === "profile" && (
        <Button
            children={"Log Out"}
            onClick={callback}
            variant="outline-danger"
            {...props}
        />
    );
};

//* Exports
export { LogOutButton };