/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `HeaderSubtitleBadge`
 *
 * Created: Oct 28, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Main
// Package Imports
import { Badge } from "react-bootstrap";
// Project Imports
import { useContext } from "react";
import { PageContext } from "../../../context";

//* Main
const HeaderSubtitleBadge = (props) => {
    // Props
    const { description } = props;
    // Hooks
    const { website } = useContext(PageContext);
    // Component Logic
    const { bg } = website.colorScheme;
    // Return
    return (
        <small>
            <a href={description}>
                <Badge
                    bg={bg}
                    children={description}
                    className={`border border-secondary`}
                    text="muted"
                />
            </a>
        </small>
    );
};

//* Exports
export { HeaderSubtitleBadge };