/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `BackButton`
 *
 * Created: Oct 28, 2022
 * Modified: Jan 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Main
// Package Imports
import { Button } from "react-bootstrap";
// Project Imports
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { PageContext } from "../../../context";

//* Main
const BackButton = () => {
    // Hooks
    const { pageData, website } = useContext(PageContext);
    const navigate = useNavigate();
    // Component Logic
    const { location, page } = pageData;
    const { profileData, projectData } = website;
    // - path
    const { path } = location;
    // - source
    let source = page;
    if (path === "") source = projectData;
    else if (path === "profile") source = profileData;
    let { hasBackButton, } = source;
    // Return
    return hasBackButton && (
        <Button
            className="rounded-circle"
            children={"←"}
            onClick={() => navigate(-1)}
            variant={`outline-secondary`}
        />
    );
};

//* Exports
export { BackButton };