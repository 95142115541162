/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controllers | `auth`
 *
 * Created: Nov 8, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import { clientId } from "./data";

//* Main
const handleLogin = async (login, oidcIssuer) => {
    const redirectUrl = window.location.href;
    console.log(oidcIssuer);
    await login({ clientId, oidcIssuer, redirectUrl });
};

//* Export
export { handleLogin };