/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Data | `ColorScheme`
 *
 * Created: Oct 11, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Main
const colorSchemes = {
    dark: {
        body: "black",
        bg: "dark",
        text: "light",
        bg_color: "#1B2124",
        text_color: "#F6F8F9"
    },
    light: {
        body: "white",
        bg: "light",
        text: "dark",
        text_color: "#1B2124",
        bg_color: "#F6F8F9"
    }
};

//* Exports
export { colorSchemes };