/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Controller | `Model`
 *
 * Created: Jan 25, 2023
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
// Project Imports
import { Model } from "./index";
import { HomeModel } from "./models/HomeModel";
import { GridModel } from "./models/GridModel";
import { ContactModel } from "./models/ContactModel";
import { GraphModel } from "./models/GraphModel";

//* Main
const getModel = (path) => {
    let model = Model;
    if (path === "") model = HomeModel;
    if (path === "archive") model = GridModel;
    if (path === "contact") model = ContactModel;
    if (path === "solid") model = GraphModel;
    return model;
};

//* Exports
export { getModel };