/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Model.Foreground`
 *
 * Created: Nov 8, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import "./index.css";

//* Main
const Foreground = (props) => {
    // Props
    const { children } = props;
    // Return
    return children && (
        <div id="Foreground">
            {children}
        </div>
    );
};

//* Exports
export { Foreground };