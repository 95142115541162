/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Component | `Page`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Project Imports
import "./index.css";
import { Header } from "./content/Header";
import { Footer } from "./content/Footer";
import { Helmet } from "./content/Helmet";
import { PageContext } from "./context";
import { useContext } from "react";

//* Main
const Page = () => {
    // Hooks
    const { pageData } = useContext(PageContext);
    // Component Logic
    const { model } = pageData.modelData;
    const { Background, Foreground } = model;
    // Return
    return (<>
        <Helmet/>
        <Header/>
        <Background/>
        <Foreground/>
        <Footer/>
    </>);
};

//* Exports
export { Page };