/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Container | `Footer`
 *
 * Created: Sep 26, 2022
 * Modified: Jan 28, 2023
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
import "./index.css";
import { useContext } from "react";
import { PageContext } from "../../context";

//* Main
const FooterContainer = (props) => {
    // Props
    const { children } = props;
    // Hooks
    const { pageData } = useContext(PageContext);
    // Component Logic
    const { modelData, page } = pageData;
    const { footerPosition } = page;
    const { model } = modelData;
    const position = model.name === "Graph" ? "fixed-bottom" : footerPosition;
    // Return
    return position && (
        <footer id="Footer" className={`${position} text-center`}>
            {children}
        </footer>
    );
};

//* Exports
export { FooterContainer };