/*
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 * # semantyk.com
 * Hooks | `ColorScheme`
 *
 * Created: Dec 27, 2022
 * Modified: Dec 28, 2022
 *
 * Copyright © Semantyk 2023. All rights reserved.
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
 */

//* Imports
// Package Imports
import { useEffect, useState } from "react";
// Project Imports
import { colorSchemes } from "./data";
import { getInicialColorScheme } from "./controller";

//* Main
const useColorScheme = () => {
    // Hooks
    const [colorScheme, setColorScheme] = useState(getInicialColorScheme);
    // Use Effect
    useEffect(() => {
        const { dark, light } = colorSchemes;
        const query = "(prefers-color-scheme: dark)";
        const callback = ({ matches }) => setColorScheme(matches ? dark : light);
        window.matchMedia(query).addEventListener("change", callback);
    }, []);
    // Return
    return colorScheme;
};

//* Exports
export { useColorScheme };